import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import DigitalMenuSection from "../DigitalMenuSection";

const Home = () => {
  const history = useHistory();

  const handleCardClick = () => {
    history.push('/order');
  };

  return (
    <div>
      <Header />
      {/* Hero section */}
      <div
        style={{
          backgroundImage: `url('https://images.squarespace-cdn.com/content/v1/624c2eed1c59e31dad7dabf4/7e83dbba-b5ee-4017-ac07-3aed64ff75e1/DSC00436+%281%29.jpg')`,
          backgroundSize: 'cover',
          height: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textAlign: 'center',
        }}
      >
        <div>
          <h1>Order Delicious Food Online</h1>
            <h2 className="orderonlinebtn"><a href="/order">ORDER ONLINE</a></h2>
        </div>
      </div>

      {/* Three cards section */}
      <Container style={{ marginTop: '50px' }}>
        <Row>
          <Col onClick={handleCardClick} style={{ cursor: 'pointer' }}>
            <Card>
              <Card.Img variant="top" src="https://images.squarespace-cdn.com/content/v1/624c2eed1c59e31dad7dabf4/7e83dbba-b5ee-4017-ac07-3aed64ff75e1/DSC00436+%281%29.jpg" />
              <Card.Body style={{ textAlign: 'center' }}>
                <Card.Title>Breakfast</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col onClick={handleCardClick} style={{ cursor: 'pointer' }}>
            <Card>
              <Card.Img variant="top" src="https://images.squarespace-cdn.com/content/v1/624c2eed1c59e31dad7dabf4/e8f8a306-b08d-4d89-ba0c-509e0cc65f1c/_DSC5363+%281%29.jpg" />
              <Card.Body style={{ textAlign: 'center' }}>
                <Card.Title>Lunch</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col onClick={handleCardClick} style={{ cursor: 'pointer' }}>
            <Card>
              <Card.Img variant="top" src="https://images.squarespace-cdn.com/content/v1/624c2eed1c59e31dad7dabf4/e9ecf3c5-964b-4b25-86f1-28c441b20a7d/DSC00424.jpg" />
              <Card.Body style={{ textAlign: 'center' }}>
                <Card.Title>Dinner</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* About Us section */}
      <Container style={{ marginTop: '50px', marginBottom: '50px' }}>
        <Row>
          <Col>
            <h2 style={{ textAlign:"center" }}>
Menu</h2>
          <DigitalMenuSection />
</Col>
</Row>
</Container>



<Footer />
</div>
);
};

export default Home;
