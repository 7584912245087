import React,{useEffect,useMemo,useState} from 'react'
import { useSelector,useDispatch, useStore } from 'react-redux';



const Gallery1 = () => {
  const merchant_data = useSelector(state =>state.MerchantToken)
    const [images, setImages] = useState([]);
    const [merchantInfo,setMerchantInfo] = useState([])

    useMemo(()=>{
       setMerchantInfo(merchant_data.merchant_token.object)
   },[merchant_data && merchant_data.merchant_token && merchant_data.merchant_token.object])

  // Your access token
  const accessToken = "eyJhbGciOiJIUzUxMiJ9.eyJhY2Nlc3NfdG9rZW4iOiIzYjNkNzM2Zi02NmFlLTQ5NDUtYjc5NC0wNjhlNzQ1NTk5ZTciLCJzdWIiOiJvcmcuc3ByaW5nZnJhbWV3b3JrLnNlY3VyaXR5LmNvcmUudXNlcmRldGFpbHMuVXNlckBlM2YxZTI2MTogVXNlcm5hbWU6IGRpbmluZ3Rla2RlbW9yZXN0YXVyYW50OyBQYXNzd29yZDogW1BST1RFQ1RFRF07IEVuYWJsZWQ6IHRydWU7IEFjY291bnROb25FeHBpcmVkOiB0cnVlOyBjcmVkZW50aWFsc05vbkV4cGlyZWQ6IHRydWU7IEFjY291bnROb25Mb2NrZWQ6IHRydWU7IE5vdCBncmFudGVkIGFueSBhdXRob3JpdGllcyIsImF1ZGllbmNlIjoid2ViIiwiY3JlYXRlZCI6MTY2MDc0OTYzNzAyNCwiZXhwIjo3NzY2MjgxMjkyMjAxODc3fQ.9JjqBp60uamrHNLUDod7EuMkxWX5OGvyI6EXoFNjGt0umIBn6AKMXbqSx34azQVmL713PPBbNXaassOKeyI9IQ";

  useEffect(() => {
      if(merchantInfo && merchantInfo.access_token){
        //const user_token = merchantInfo.access_token
        fetchImages();
      }
    fetchImages();
  }, [merchantInfo]);

  const fetchImages = async () => {
    try {
      const response = await fetch(
        `https://prod.diningtek.com/api/v1/merchants/galleries?h=400&w=400&type=BANNER&access_token=${merchantInfo.access_token}`
      );
      const data = await response.json();
      setImages(data.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const renderImages = () => {
    if (!images) {
    return null;
  }
    return images.map((image, index) => (
      <div key={index} className="gallery-image">
        <img src={image.data} alt={image.imageText} />
      </div>
    ));
  };

  return <div className="gallery-container">{renderImages()}</div>;
};

export default Gallery1;
