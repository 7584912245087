import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import DigitalMenuSection from "../DigitalMenuSection";

const AboutUs = () => {
  const history = useHistory();

  const handleCardClick = () => {
    history.push('/');
  };

  return (
    <div>
      <Header />
      {/* Hero section */}
      <div
        style={{
          backgroundImage: `url('https://images.squarespace-cdn.com/content/v1/624c2eed1c59e31dad7dabf4/7e83dbba-b5ee-4017-ac07-3aed64ff75e1/DSC00436+%281%29.jpg')`,
          backgroundSize: 'cover',
          height: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textAlign: 'center',
        }}
      >
        <div>
          <h1>Order Delicious Food Online</h1>
            <h2 className="orderonlinebtn"><a href="/order">ORDER ONLINE</a></h2>
        </div>
      </div>


      {/* About Us section */}
      <Container style={{ marginTop: '50px', marginBottom: '50px' }}>
        <Row>
          <Col>
            <h2 style={{ textAlign:"center" }}>
A BRIEF HISTORY OF CHAPALA</h2>
            <p style={{ fontSize: '20px', lineHeight: '1.5' }}>
            Chapala is a town and municipality in the central Mexican state of Jalisco, located on the north shore of Lake Chapala, Mexico’s largest freshwater lake.

Until the arrival of the Spanish in the 16th century, nomadic Amerindian tribes, including the Nahuatl, occupied the Lake Chapala region. They migrated from northwestern Mexico and gave rise to the Aztec civilization. Chapala is named after the last chief of the Nahuatl, Chapalac. Ajijic, the second largest town along the lake’s northwestern shore, received its name from the Nahuatl word for “Place where the water springs forth.”

Lake Chapala in southeastern Jalisco is Mexico’s largest lake, occupying about 1,112 square kilometers (430 square miles). The lake provides critical habitat for several species of migratory birds, including the white pelican.
</p>
</Col>
</Row>
</Container>

<Footer />
</div>
);
};

export default AboutUs;
