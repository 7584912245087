import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import DigitalMenuSection from "../DigitalMenuSection";

const ContactUs = () => {
  const history = useHistory();

  const handleCardClick = () => {
    history.push('/');
  };

  return (
    <div>
      <Header />
      {/* Hero section */}

      {/* About Us section */}
      <Container style={{ marginTop: '50px', marginBottom: '50px' }}>
        <Row>
          <Col>
          <h1 style={{textAlign:"center"}}>Contact Info</h1>
          <div className="rview"><div className="row no-gutters"><div className="col-lg-6 col-md-6"><div className="rview-one-wrapper"><div className="rview-one first"><h6>Phone Number</h6><p>(303) 506-0082</p></div><div className="rview-one"><h6>Address</h6><p>390 Interlocken Crescent Suite 350</p><p>Broomfield, Colorado </p><p>UNITED STATES</p></div></div></div><div className="col-lg-6 col-md-6"><div className="r-maps-wrapper"><div className="r-maps"><iframe src="https://maps.google.com/maps?q=39.9253372,-105.115069&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder={0} allowFullScreen style={{border: '0px'}} /></div></div></div></div></div>
</Col>
</Row>
</Container>

<Footer />
</div>
);
};

export default ContactUs;
